var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M2.66634 8.00358L3.60634 8.94358L7.33301 5.22358L7.33301 13.3369L8.66634 13.3369L8.66634 5.22358L12.3863 8.95025L13.333 8.00358L7.99967 2.67025L2.66634 8.00358Z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M7.99967 13.3366L8.93967 12.3966L5.21967 8.66992L13.333 8.66992L13.333 7.33659L5.21967 7.33659L8.94634 3.61659L7.99967 2.66992L2.66634 8.00325L7.99967 13.3366Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }