import { render, staticRenderFns } from "./tit-wrap-tertiary.vue?vue&type=template&id=29e30b17&scoped=true&"
import script from "./tit-wrap-tertiary.vue?vue&type=script&lang=js&"
export * from "./tit-wrap-tertiary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e30b17",
  null
  
)

export default component.exports