<template>
    <tit-wrap v-bind="$attrs" class="tit-wrap--top-line tit-wrap--txtadd-sm">
        <v-divider data-aos="fade-down" class="primary border-2 border-md-4 mb-12px mb-md-16px" />
        <slot />
    </tit-wrap>
</template>

<script>
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";

export default {
    props: {},
    components: {
        TitWrap,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit-wrap--top-line{
    text-align: center;
    ::v-deep{
        .v-divider{
            max-width: 32px;
            margin: 0 auto;
        }
    }
}
</style>
