<template>
    <svg
        v-if="direction == 'up' || direction == 'down'"
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        class="icon-svg icon-arrow"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path d="M2.66634 8.00358L3.60634 8.94358L7.33301 5.22358L7.33301 13.3369L8.66634 13.3369L8.66634 5.22358L12.3863 8.95025L13.333 8.00358L7.99967 2.67025L2.66634 8.00358Z" />
    </svg>
    <svg
        v-else
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        class="icon-svg icon-arrow"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path d="M7.99967 13.3366L8.93967 12.3966L5.21967 8.66992L13.333 8.66992L13.333 7.33659L5.21967 7.33659L8.94634 3.61659L7.99967 2.66992L2.66634 8.00325L7.99967 13.3366Z" />

    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 10px;
            }
            &-x-small {
                width: 12px;
            }
            &-small {
                width: 14px;
            }
            &-default {
                width: 16px;
            }
            &-large {
                width: 18px;
            }
            &-x-large {
                width: 20px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 10px;
            }
            &-x-small {
                height: 12px;
            }
            &-small {
                height: 14px;
            }
            &-default {
                height: 16px;
            }
            &-large {
                height: 18px;
            }
            &-x-large {
                height: 20px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
