<template>
    <tit v-bind="$attrs" class="tit--subtit"><slot /></tit>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {},
    components: {
        Tit,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit {
    // subtit
    &--subtit{
        font-size: var(--tit-font-size-xxs);
        color: var(--v-primary-base);
        margin-bottom: 4px;
    }
}
</style>