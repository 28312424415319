<template>
    <u-tit-wrap-top-line v-bind="$attrs">
        <!-- <u-tit-subtit v-if="this.$slots['TitAdd']" class="tit--xs"><slot name="TitAdd" /></u-tit-subtit> -->
        <u-tit-default data-aos="fade-down" data-aos-delay="50" class="break-keep"><slot /></u-tit-default>
        <u-txt-default data-aos="fade-up" data-aos-delay="100" v-if="this.$slots['TxtAdd']" class="txt--dark line-height-15 font-weight-medium font-secondary"><slot name="TxtAdd" /></u-txt-default>
    </u-tit-wrap-top-line>
</template>

<script>
import UTitWrapTopLine from "@/components/publish/styles/typography/u-tit-wrap-top-line.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UTitWrapTopLine,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
