<template>
    <u-tit-wrap-default class="tit-wrap--sm tit-wrap--txtadd-sm" v-bind="$attrs">
        <u-tit-default data-aos="fade-up" class="tit--sm break-keep" style="text-transform: capitalize;"><slot /></u-tit-default>
        <v-divider class="grey darken-4 border-2 mt-12px mt-md-16px"/>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
