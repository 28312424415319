var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({
    staticClass: "tit-wrap--sm tit-wrap--txtadd-sm"
  }, 'u-tit-wrap-default', _vm.$attrs, false), [_c('u-tit-default', {
    staticClass: "tit--sm break-keep",
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._t("default")], 2), _c('v-divider', {
    staticClass: "grey darken-4 border-2 mt-12px mt-md-16px"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }