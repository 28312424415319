var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-top-line', _vm._b({}, 'u-tit-wrap-top-line', _vm.$attrs, false), [_c('u-tit-default', {
    staticClass: "break-keep",
    attrs: {
      "data-aos": "fade-down",
      "data-aos-delay": "50"
    }
  }, [_vm._t("default")], 2), this.$slots['TxtAdd'] ? _c('u-txt-default', {
    staticClass: "txt--dark line-height-15 font-weight-medium font-secondary",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._t("TxtAdd")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }